import ImageService from "../services/imageService"

export default class CardProduct {

    constructor(product, placeholder_image = null) {
        this.cheapestSku = null
        this.skus = product.skus ?? product.current_skus
        this.allSkuPrices = []
        this.setAllSkuPrices(product)
        this.allSkusOnSpecial = this.skus.every((sku) => sku.current_specials.length)

        this.id = product.id
        this.set = product.set
        this.name = product.name
        this.slug = product.slug
        this.headline = product.headline
        this.short_description = product.short_description
        this.order_quantity = product.order_quantity
        this.imageUrl = this.getImageUrl(product, placeholder_image)
        this.currentProductSpecial = this.getCurrentProductSpecial(product)
        this.currentSkuSpecial = this.getCurrentSkuSpecial(product)
        this.currentProductSpecialPrice = this.currentProductSpecial?.pivot?.special_price;
        this.currentSkuSpecialPrice = this.currentSkuSpecial?.pivot?.special_price
        this.skusOnSpecial = this.skus.some((sku) => sku.current_specials.length),
        this.skusSamePrice = this.allSkuPrices.every(skuPrice => skuPrice == this.skus[0].price);
        this.lowestPrice = this.getLowestPrice()
        

        this.specialPrice = this.currentProductSpecialPrice
              ? this.currentProductSpecialPrice
              : this.currentSkuSpecialPrice;
        this.regularPrice = (product.set || product.order_quantity > 1) ? product.price : this.cheapestSku?.price;
        this.useSpecialPrice = this.specialPrice < this.regularPrice && (this.currentProductSpecial || (this.currentSkuSpecial && this.cheapestSku.current_specials[0]?.pivot.special_price == this.lowestPrice))
        this.pricePrefix = product.set || this.skus.length == 1 || this.skusSamePrice
            ? ""
            : "from "
        this.priceSuffix = product.order_quantity > 1 ? ` for set of ${product.order_quantity}` : ""
        this.special = this.currentProductSpecial ? this.currentProductSpecial : this.currentSkuSpecial
        this.allOnSpecial = this.currentProductSpecial ? true : this.allSkusOnSpecial
    }

    getImageUrl(product, placeholder_image) {
        const image = product.images?.length
            ? product.images[product.display_image_index ?? 0]
            : placeholder_image ?? "";

        return ImageService.url(image, 'sm');
    }

    getCurrentProductSpecial(product) {
        const special = product.current_specials.length
          ? product.current_specials[0]
          : null;

        return special;
      }

      getCurrentProductSpecialPrice() {
        return this.productSpecial?.pivot?.special_price;
      }

      getCurrentSkuSpecial(product) {
        const special = this.skus.reduce((special, sku) => {
          if (
            sku.current_specials.length &&
            (!special ||
              special.pivot.special_price >
                sku.current_specials[0].pivot.special_price)
          ) {
            special = sku.current_specials[0];
          }
          return special;
        }, null);
  
        return special;
      }

      getCurrentSkuSpecialPrice() {
        return this.currentSkuSpecial?.pivot?.special_price;
      }

      getSkusOnSpecial() {
        return this.skus.some((sku) => sku.current_specials.length);
      }

      getLowestPrice() {
        return Math.min(...this.allSkuPrices);
      }

      setAllSkuPrices(product) {
        const { prices, cheapestSku } = this.skus.reduce(
          (data, sku) => {
            const prices = [
              sku.price,
              ...sku.current_specials.map((cs) => cs.pivot.special_price),
            ];
            if (!data.cheapestSku || Math.min(...data.prices) > Math.min(...prices)) {
              data.cheapestSku = sku;
            }
            data.prices = [...prices, ...data.prices];
            return data;
          },
          { prices: [], cheapestSku: null }
        );
        this.allSkuPrices = prices;
        this.cheapestSku = cheapestSku;
      }

      getAllSkusOnSpecial() {
        return this.skus.every((sku) => sku.current_specials.length);
      }

      getSkusSamePrice() {
        return this.allSkuPrices.every(
          (skuPrice) => skuPrice == this.skus[0].price
        );
      }

}