<template>
  <div :class="`grid ${price_group_class}`" v-if="product.useSpecialPrice"
    >
      <span :class="`${price_class}`">
        {{ product.pricePrefix }}${{ formatPrice(product.specialPrice) }}
      
        <span :class="regular_price_class">${{ formatPrice(product.regularPrice) }}</span>
        {{ product.priceSuffix }}
      </span>
  </div>
  <div :class="`grid ${price_group_class} ${price_class}`" v-else
    >{{ product.pricePrefix }}${{ formatPrice(product.regularPrice)}}{{ product.priceSuffix }}</div
  >
</template>

<script>
export default {
  props: ["product", 'price_class', 'regular_price_class', 'price_group_class'],
  methods: {
    formatPrice(price) {
      return (price / 100).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
  },
};
</script>
