<template>
  <div :class="`c_${component_id}__inner_container c_${component_id}__inner_container_${theme_id ?? ''} relative min-h-80`" v-show="chunkedProducts.length && item">
    <div class=" my-4" >
      <div
        :id="`${type}_${item?.id ?? ''}_container`"
        class="paginated-product-slider__section -m-4"
        ref="container"
      >
        <section
      
        >
          <a :href="link">
            <h2
              :class="`c_${component_id}__heading c_${component_id}__heading_${theme_id ?? ''} heading`"
            >
              {{ heading ? heading : type == "product" ? "Associated Products" : item?.name ?? '' }}
            </h2>
          </a>

          <div class="w-full relative overflow-hidden">
            <div class="absolute top-0 bottom-0 -left-2 grid items-center">
              <button
                @click.prevent="decreaseChunkIndex"
                v-if="chunkIndex > 0"
                class="z-10"
                aria-label="slide images left"
              >
                <chevron-left-icon :class="`c_${component_id}__left_arrow c_${component_id}__left_arrow_${theme_id ?? ''}`" />
              </button>
            </div>
            <div class="absolute top-0 bottom-0 -right-2 grid items-center">
              <button
                @click.prevent="increaseChunkIndex"
                v-if="!loadedAll || chunkIndex + 1 < chunkedProducts.length"
                class="z-10"
                aria-label="slide images right"
              >
                <chevron-right-icon :class="`c_${component_id}__right_arrow c_${component_id}__right_arrow_${theme_id ?? ''}`" />
              </button>
            </div>
            <ul
              class="w-full overflow-hidden whitespace-nowrap"
              :class="`chunk-${item?.slug ?? ''}`"
            >
              <li
                class="inline-flex w-full"
                v-for="(chunk, index) in chunkedProducts"
                :key="`chuck${index}`"
              >
                <div
                  class="
                    w-full
                    tagChunk
                    grid
                    p-4
                    gap-4
                  "
                  :class="colNumber"
                  @touchstart="touchStartHandler"
                  @touchend="touchEndHandler"
                >
                  <a
                    :href="`/shop/products/${product.slug}`"
                    v-for="(product, index) in chunk"
                    :key="product.id"
                    @click="registerClick(product)"
                    class="grid"
                  >
                    <slider-product-card 
                    :slider_component_id="component_id" 
                    :theme_id="theme_id" 
                    :component_id="product_card_component_id" 
                    :product="product" 
                    :card_number="(index + 1)" 
                    :card="item_settings?.[`card${index + 1}`]" 
                    :placeholder_image="placeholder_image"
                    />
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import ChevronLeftIcon from "./ChevronLeftIcon.vue";
import ChevronRightIcon from "./ChevronRightIcon.vue";
import SliderProductCard from "./SliderProductCard.vue";

export default {
  components: {
    ChevronLeftIcon,
    ChevronRightIcon,
    SliderProductCard,
  },
  props: {
    link: String,
    heading: String,
    item: Object,
    item_settings: Object,
    type: String,
    odd: Number,
    small: Boolean,
    theme_id: String|null,
    container_tag: String,
    container_id: String,
    container_size: Number,
    component_id: String,
    product_card_component_id: String,
    placeholder_image: Object,
  },
  data() {
    return {
      chunkIndex: 0,
      screenWidth: null,
      chunkedProducts: [],
      page: 0,
      total: null,
      startX: null,
      products: [],
      colNumber: 'grid-cols-1',
    };
  },
  computed: {
    pluaralisedTypeForUrl() {
      switch (this.type) {
        case "brand":
          return "brands";
        case "category":
          return "categories";
        case "product":
          return "products";
        case "tag":
          return "tags";
        case "special":
          return "specials";
        default:
          return "";
      }
    },
    nextPage() {
      return this.page + 1;
    },
    loadedAll() {
      return this.total && this.total == this.products.length;
    },
    loadNext() {
      return (
        !this.loadedAll && this.chunkedProducts.length == this.chunkIndex + 1
      );
    },
    url() {
      if(!this.item) return ''
      
      return this.type == "product"
        ? ` /admin/products/${this.item.slug}/associated_products?page=${this.nextPage}`
        : `/${this.pluaralisedTypeForUrl}/${this.item.id}/products?page=${this.nextPage}`;
    },
  },
  methods: {
    setColNumber() {
      let containerWidth = document.querySelector(
        `${this.container_tag}#${this.container_id}`
      ).clientWidth;

      if (containerWidth < 640) {
        this.colNumber = 'grid-cols-1';
      }
      else if (containerWidth < 768) {
        this.colNumber = 'grid-cols-2';
      }
      else if (containerWidth < 1024) {
        this.colNumber = 'grid-cols-3';
      } else {
        this.colNumber = 'grid-cols-4';
      }
    },
    registerClick(product) {
      if ("dataLayer" in window) {
        dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
        dataLayer.push({
          event: "productClick",
          ecommerce: {
            click: {
              products: [
                {
                  name: product.name, // Name or ID is required.
                  id: product.id,
                },
              ],
            },
          },
        });
      }
    },
    touchStartHandler(e) {
      this.startX = e.changedTouches[0].pageX;
    },
    touchEndHandler(e) {
      const endX = e.changedTouches[0].pageX;
      if(this.startX && Math.abs(this.startX - endX) > 50) {
        e.preventDefault()
      }
      if (this.startX && Math.abs(this.startX - endX) > 50) {
        if (this.startX > endX) {
          this.increaseChunkIndex();
        } else {
          this.decreaseChunkIndex();
        }
      } else {
        // window.location = this.link
      }

      this.startX = null;
    },
    loadProducts() {
      return new Promise((resolve, reject) => {
        if(!this.item) resolve()
        this.show_spinner = true;
        axios({
          method: "get",
          url: this.url,
        })
          .then(({ data }) => {
            this.total = data.total;
            this.page = data.current_page;
            this.products = [...this.products, ...data.data];
            this.show_spinner = false;
            resolve();
          })
          .catch((error) => {
            this.show_spinner = false;
            reject(error);
          });
      });
    },
    productsPerChunk() {
      let containerWidth = document.querySelector(
      `${this.container_tag}#${this.container_id}`
    ).clientWidth;
      if (containerWidth < 640) {
        return 1;
      }
      if (containerWidth < 768) {
        return 2;
      }
      if (containerWidth < 1024) {
        return 3;
      } else {
        return 4;
      }
    },
    chunkProducts() {
      const productsPerChunk = this.productsPerChunk();
      return this.products.reduce((chunks, product, index) => {
        const chunkIndex = Math.floor(index / productsPerChunk);
        if (index % productsPerChunk == 0) {
          chunks[chunkIndex] = [];
        }
        chunks[chunkIndex].push(product);
        return chunks;
      }, []);
    },
    listElement() {
      return document.querySelector(`ul.chunk-${this.item?.slug ?? ''}`);
    },
    listElementWidth() {
      // return window.getComputedStyle(this.listElement()).width;
      return this.$refs.container.clientWidth
    },
    increaseChunkIndex() {
      if (this.chunkIndex + 1 <= this.chunkedProducts.length) {
        if (this.loadNext) {
          this.loadProducts().then(() => {
            this.chunkedProducts = this.chunkProducts();
            this.chunkIndex++;
            this.$nextTick(() => {
              this.listElement().scroll({
                top: 0,
                left:
                  this.listElementWidth() * this.chunkIndex,
                behavior: "smooth",
              });
            });
          });
        } else {
          this.chunkIndex++;
          this.listElement().scroll({
            top: 0,
            left: this.listElementWidth() * this.chunkIndex,
            behavior: "smooth",
          });
        }
      }
    },
    decreaseChunkIndex() {
      if (this.chunkIndex > 0) {
        this.chunkIndex--;
        this.listElement().scroll({
          top: 0,
          left:
            this.listElementWidth() *
              this.chunkedProducts.length -
            this.listElementWidth() *
              (this.chunkedProducts.length - this.chunkIndex),
          behavior: "smooth",
        });
      }
    },
    handleResize() {
      this.setColNumber()
      this.chunkedProducts = this.chunkProducts();
      this.chunkIndex = 0;
      this.listElement().scroll({
        top: 0,
        left: -this.listElement().scrollLeft,
        behavior: "smooth",
      });
    }
  },
  mounted() {
    
    const container = document.querySelector(
      `${this.container_tag}#${this.container_id}`
    );
    const observer1 = new IntersectionObserver((entries, observer) => {
      if (entries[0].intersectionRatio > 0) {
        this.loadProducts().then(() => {
         this.setColNumber()
        this.chunkedProducts = this.chunkProducts();
        window.addEventListener("resize", () => {
          this.handleResize()
        });
      });
      observer.unobserve(entries[0].target);
      }
    }, { rootMargin: "600px" });
    const observer2 = new IntersectionObserver((entries, observer) => {
      if (entries[0].intersectionRatio > 0) {
        entries[0].target.style.animation = `slide 1s forwards ease-out`;
        observer.unobserve(entries[0].target);
      }
    });
    observer1.observe(container);
    observer2.observe(container);
  },
};
</script>
<style scoped>
</style>
