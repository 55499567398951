<template>
  <span
    class="
      absolute
      top-1
      left-0
      py-1
      px-2
      text-red-500
      bg-white
      rounded-r
      flex
      gap-2
      items-center
      shadow-sm
    "
    v-if="special"
  >
    <span class="flex flex-col">
      <span class="uppercase text-xs font-bold text-pretty">
        {{ discountDescription }}
      </span>
      <span class="text-xxs">
        Ends
        {{ formatDate(special.expires_at) }}
      </span>
    </span>
  </span>
</template>

<script>
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
dayjs.extend(relativeTime);
export default {
  props: ["special", "all"],
  data() {
    return {
      size: "2rem",
      colour: "#000000",
    };
  },
  computed: {
    discountDescription() {
      return this.special?.label
        ? this.special.label
        :this.special?.discount_type === "Percent"
          ? `${this.special.discount_amount}% off${this.all ? "" : " some items"}`
          : `$${(this.special.discount_amount / 100).toFixed(2)} off${this.all ? "" : " some items"}`;
    },
  },
  methods: {
    formatDate(date) {
      return dayjs().to(dayjs(date).endOf("day"));
    },
  },
  mounted() {},
};
</script>
