<template>
  <button
    class="focus:outline-none"
    @click.prevent="add"
    @mouseover="colour = '#907569'"
    @mouseleave="colour = '#f0c3b0'"
    :aria-label="`add ${product.name} to your wishlist`"
  >
    <svg
      xmlns="http://www.w3.org/2000/svg"
      x="0px"
      y="0px"
      viewBox="0 0 172 172"
      :class="class"
    >
      <g
        fill="inherit"
        fill-rule="nonzero"
        stroke="none"
        stroke-width="4"
        stroke-linecap="butt"
        stroke-linejoin="miter"
        stroke-miterlimit="10"
        stroke-dasharray=""
        stroke-dashoffset="0"
        font-family="none"
        font-weight="none"
        font-size="none"
        text-anchor="none"
        style="mix-blend-mode: normal"
      >
        <path d="M0,172v-172h172v172z" fill="none"></path>
        <g fill="inherit">
          <path
            d="M51.6,24.08c-24.65781,0 -44.72,20.06219 -44.72,44.72c0,50.78031 57.43188,75.84125 76.97,92.1275l2.15,1.8275l2.15,-1.8275c19.53813,-16.28625 76.97,-41.34719 76.97,-92.1275c0,-24.65781 -20.06219,-44.72 -44.72,-44.72c-13.94812,0 -26.20312,6.62469 -34.4,16.6625c-8.19687,-10.03781 -20.45187,-16.6625 -34.4,-16.6625zM51.6,30.96c13.19563,0 24.725,6.7725 31.4975,16.985l2.9025,4.3l2.9025,-4.3c6.7725,-10.2125 18.30188,-16.985 31.4975,-16.985c20.93563,0 37.84,16.90438 37.84,37.84c0,44.37063 -49.5575,67.33531 -72.24,85.2475c-22.6825,-17.91219 -72.24,-40.87687 -72.24,-85.2475c0,-20.93562 16.90438,-37.84 37.84,-37.84z"
          ></path>
        </g>
      </g>
    </svg>
  </button>
</template>

<script>
export default {
  props: {
    product: Object,
    class: String
  },
  methods: {
    add() {
      axios({
        method: "post",
        url: `/wishlist/${this.product.slug}`,
      })
        .then((response) => {
          analyser.addToWishList(this.product, this.getPrice())
          this.eventBus.emit("flashMessage", {
            message: `${this.product.name} added to your wishlist`,
            error: false,
          });
        })
        .catch((error) => {
          if (error.response.status == 401) {
            this.eventBus.emit("flashMessage", {
              message: "Whoops, you need to log in to do that",
              error: true,
            });
            window.location = "/login";
          } else if (error.response.status == 404) {
            this.eventBus.emit("flashMessage", {
              message: error.response.data.message,
              error: true,
            });
          } else {
            this.eventBus.emit("flashMessage", {
              message: "Sorry, something went wrong",
              error: true,
            });
          }
        });
    },
    getPrice() {
      return this.product.price 
        ? (this.product.price / 100).toFixed(2)
        : (Math.min(this.product.skus.reduce((prices, sku) => prices.push(sku.price), [])) / 100).toFixed(2)
    },
  },
};
</script>